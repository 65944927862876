.fc-day-today {
  background-color: var(--fc-button-bg-color)!important;
}

.fc-day-today .fc-daygrid-day-number {
  color: white!important;
}

.home {
  width: 100%;
  height: 100%;
}

.fc {
  max-width: 100vw;
  max-height: 100vh;
}

:root {
  --rating-1: #FF0000;
  --rating-2: #FF3300;
  --rating-3: #FF6600;
  --rating-4: #FF9900;
  --rating-5: #FFCC00;
  --rating-6: #FFFF00;
  --rating-7: #CCFF00;
  --rating-8: #99FF00;
  --rating-9: #66FF00;
  --rating-10: #33FF00;
  --rating-empty: var(--mdb-light-gray);
}

.form-range {
  --current-rating-color: var(--rating-5);
}

.form-range::-webkit-slider-thumb {
  background-color: var(--current-rating-color)!important;
}

.range .thumb::after {
  background-color: var(--current-rating-color)!important;
}

.thumb-value {
  color: var(--mdb-heading-color)!important;
}

.pointer-cursor {
  cursor: pointer;
}

.fc-h-event {
  border: none!important;
}

.fc-daygrid-bg-harness {
  color: black!important;
  overflow: hidden!important;
}

#textAreaExample {
  resize: none;
  height: 40vh!important;
  margin-bottom: 1rem;
}

.loading-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--fc-button-bg-color);
  color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

label[for="customRange2"] {
  margin-bottom: 0;
}

.badge-primary {
  background-color: black!important;
  color: white!important;
}

.add-custom-tracker-btn {
  color: var(--mdb-success);
  cursor: pointer;
}

.custom-tracker-trash-can {
  color: var(--mdb-danger);
  cursor: pointer;
}

.custom-tracker-modal-close-btn {
  margin-right: .5rem;
}

.fc-daygrid-day-number,
.fc-col-header-cell-cushion {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.mt-half {
  margin-top: .125rem;
}

.checkbox-custom-tracker-icon {
  cursor: pointer;
}

.checkbox-custom-tracker-icon:hover {
  font-size: 1.5rem;
}

.icons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  gap: 1rem;
  row-gap: 3rem;
  overflow-y: auto;
  height: 60vh;
}

.icons-grid i {
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  transition: font-size 0.3s;
}

.icons-grid i:hover,
.icons-grid i.selected {
  font-size: 2rem;
}

.checkbox-custom-tracker-icon,
.active-custom-tracker-color {
  color: var(--mdb-primary);
}

.view-entry-text-content-box {
  white-space: pre-wrap;
  border: var(--mdb-secondary) 1px solid;
  padding: .5rem;
  border-radius: .5rem;
}

.p-button {
  border-radius: .5rem;
}

.p-fileupload-buttonbar span.p-button.p-fileupload-choose {
  margin-right: 0;
}

.p-fileupload-highlight {
  border: 2px dashed #3f51b5;
  background-color: #e3f2fd;
}

.p-fileupload-buttonbar {
  display: flex;
  justify-content: space-between;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 90%;
  max-width: 800px;
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.img-thumbnail {
  border-radius: .5rem;
  width: 50px;
  margin-right: .5rem;
}

.image-grid {
  display: grid;
  gap: 1rem;
}
.image-grid img {
  width: 100%;
  height: auto;
  display: block;
}

.p-button {
  height: 2.75rem;
}

@media screen and (max-width: 500px) {
  .p-button {
    width: 100%;
    height: 1.5rem;
    font-size: .75rem;
  }
}

.fas.entry-modal-display-star,
.fas.view-entry-modal-display-star,
.fas.date-display-star {
  color: var(--mdb-warning);
}

.fas.date-display-star {
  color: #B59410;
}

.entry-modal-display-star,
.view-entry-modal-display-star {
  cursor: pointer;
}

.fc-toolbar-title {
  position: relative;
  display: inline-block;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.fc-toolbar-title::after {
  content: "Summarize month";
  position: absolute;
  top: 100%; /* Position below the element */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  pointer-events: none; /* Prevent mouse events */
  z-index: 1000;
}

.fc-toolbar-title:hover::after {
  opacity: 1;
  visibility: visible;
}
