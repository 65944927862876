@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: Comfortaa;
}

html, body {
  width: 100%;
  height: 100%;
}